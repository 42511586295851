import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { DigitalConcierge } from 'app/components/DigitalConcierge'
import { GalleryImages } from 'app/components/GalleryImages'
import { Hero } from 'app/components/Hero'
import { ImagesParagraph } from 'app/components/ImagesParagraph'
import { Info } from 'app/components/Info'
import { Intro } from 'app/components/Intro'
import { Review } from 'app/components/Review'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { ZigZagVisual } from 'app/components/ZigZagVisual'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Props as SidebarProps, Sidebar } from 'app/containers/Sidebar'
import { Media, MediaContextProvider } from 'app/theme/media'
import { PageProps } from 'gatsby'
import React, { memo, useLayoutEffect } from 'react'
import { hotjar } from 'react-hotjar'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  sidebarProps?: SidebarProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function RestaurantPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  useLayoutEffect(() => {
    hotjar.initialize(2951797, 6)
  }, [])

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.sidebarProps ? (
        <Sidebar
          languageCode={context.languageCode}
          {...context.sidebarProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? (
        <Intro variant="dark" {...context.introProps} />
      ) : null}
      {context.imagesParagraphProps ? (
        <ImagesParagraph {...context.imagesParagraphProps} />
      ) : null}
      {context.galleryImagesProps ? (
        <GalleryImages variant="clip" {...context.galleryImagesProps} />
      ) : null}
      {context.zigZagVisualProps ? (
        <ZigZagVisual {...context.zigZagVisualProps} />
      ) : null}
      {context.infoProps ? <Info {...context.infoProps} /> : null}
      {context.reviewProps ? <Review {...context.reviewProps} /> : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      <MediaContextProvider>
        <Media greaterThanOrEqual="desktopSmall">
          {context.digitalConciergeProps ? (
            <DigitalConcierge {...context.digitalConciergeProps} />
          ) : null}
        </Media>
      </MediaContextProvider>
      {context.footerProps ? (
        <Footer
          appTitle={
            context.digitalConciergeProps
              ? context.digitalConciergeProps.title
              : null
          }
          appSubtitle={
            context.digitalConciergeProps
              ? context.digitalConciergeProps.subtitle
              : null
          }
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
